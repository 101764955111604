.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.App {
  text-align: center;
}

.App-logo {
  width: 65%;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: 'Rock Salt';
  color: rgb(137, 147, 158);
}

.App-link {
  color: #61dafb;
}

.slick-slide.slick-center .flex.items-center {
  transform: scale(2);
  transition-duration: 350ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@media only screen and (max-width: 480px) {
  .slick-slide.slick-center .flex.items-center {
    transform: scale(1.5);
  }
}


.slick-list{
  padding-top: 3rem !important;
}

.slick-slide.slick-center img {
  margin-left: auto;
  margin-right: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes right-animate {
  0% {
    width: 5%;
  }

  100% {
    width: 85%;
  }
}

html,
body {
  height: 100%;
  width: 100%;
}

.slick-prev,
.slick-next {
  width: 40px !important;
  height: 40px !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 40px !important;
}