.App-hero-menu nav {
    text-align: center;
    -webkit-animation: nav_fade 0.8s 2.4s forwards;
    animation: nav_fade 0.8s 2.4s forwards;
    position: Relative;
}

.App-hero-menu nav a {
    color: white;
    text-decoration: none;
    font-weight: 300;
    margin: 5px;
    font-size: 18px;
    padding: 10px 20px;
    opacity: 0.5;
    text-align: center;
    position: relative;
}

.App-hero-menu nav a:hover, .App-hero-menu nav li.active  {
    opacity: 1;
}

.App-hero-menu nav a:hover:before, .App-hero-menu nav li.active a:before {
    -webkit-clip-path: inset(0px 0px 0px 0px);
    clip-path: inset(0px 0px 0px 0px);
}

.App-hero-menu nav a:hover:after, .App-hero-menu nav li.active a:after {
    -webkit-clip-path: inset(0px 0px 0px 0px);
    clip-path: inset(0px 0px 0px 0px);
}

.App-hero-menu nav a:before {
    width: 20px;
    transition: all 0.31s;
    height: 0px;
    -webkit-clip-path: inset(0px 40px 0px 0px);
    clip-path: inset(0px 40px 0px 0px);
    border-top: 2px solid white;
    content: "";
    display: block;
    position: absolute;
    top: -16px;
    left: 25px;
    right: 0;
    z-index: -1;
    margin: auto;
    transform: rotate(-60deg) translateY(-50%) translatex(-50%);
}

.App-hero-menu nav a:after {
    width: 20px;
    -webkit-clip-path: inset(0px 0px 0px 40px);
    clip-path: inset(0px 0px 0px 40px);
    transition: all 0.31s;
    height: 0px;
    border-top: 2px solid white;
    content: "";
    display: block;
    position: absolute;
    top: 36px;
    left: -2px;
    right: 0;
    z-index: -1;
    margin: auto;
    transform: rotate(-60deg) translateY(-50%) translatex(-50%);
}