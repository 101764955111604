@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100vh;
  background: #232333;
  /* Old browsers */
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 12+ */
  /* IE10+ */
  background: radial-gradient(ellipse at center, #232333 0%, #161616 100%);
  /* W3C */
  
}

.max-width {
  @apply lg:mx-auto lg:px-24 xl:px-48 px-5;
}
.bg-gradient {
  @apply bg-gradient-to-r from-indigo-500 to-blue-500;
}

.text-gradient {
  @apply bg-clip-text bg-gradient text-transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font2{
  font-family: 'Hammersmith One';
}

.font1{
  font-family: 'Rock Salt';
}

@font-face {
  font-family: 'Rock Salt';
  src: url('./fonts/RockSalt-Regular.woff2') format('woff2'),
      url('./fonts/RockSalt-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hammersmith One';
  src: url('./fonts/HammersmithOne-Regular.woff2') format('woff2'),
      url('./fonts/HammersmithOne-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
