.App-logo-container {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.App-logo-container::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: none;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
    margin-left: -80px;
}

.App-logo-container:hover::before {
    -webkit-animation: shine .75s;
    animation: shine .75s;
    display: block;
}

@-webkit-keyframes shine {
    100% {
        left: 125%;
    }
}

@keyframes shine {
    100% {
        left: 125%;
    }
}